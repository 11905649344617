<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
      >
        <v-toolbar-title>Edit Price List</v-toolbar-title>
        <v-spacer></v-spacer>
       <!--  <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          flat
          outlined
          solo
          dense
          label="search"
          style="width: 150px"
        ></v-text-field> -->
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          small
          text
          to="/inventory/price-list"
        >
          <v-icon
            left
            dark
          >mdi-keyboard-backspace</v-icon>Back
        </v-btn>

      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
          >
            <editor         
          :initial="priceList" 
          :currencies="currencies"
          :createStatus="createStatus"
          @data="save"

          ></editor>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
import { mapGetters } from "vuex";
export default {
   components: {
    Editor,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    search: "",
    currencies: [],
    priceList: {},
    createStatus: false,
    
   
  }),
  watch: {
    "$route.params.id": {
      handler: "getPriceList",
      immediate: true,
    },
  },
  methods: {
    getPriceList(val) {
      const self = this;
      this.$store
        .dispatch("get", `/price_lists/${val}`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.priceList = res.ResponseData;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
     save(data) {     
      const url = `/price_lists/${data.id}`;
      const self = this;
      self.$store
        .dispatch("put", { url, data })
        .then(() => {
           self.$router.push("/inventory/price-list");
          // self.$refs.snackbar.show("Item group created", "green");    
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
     getCurrencies() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/currency`)
        .then((res) => {
          self.currencies = res;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getCurrencies();
  },
};
</script>