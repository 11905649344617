<template>
  <v-form>
    <v-row dense>
      <v-col cols="6">
        <v-text-field
          outlined
          dense
          label="Price List Name"
          v-model="record.ListName"
        ></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-radio-group
          v-model="record.ValidFor"
          :mandatory="true"
          row
        >
          <template v-slot:label>
            <div>
              <strong>Active</strong>
            </div>
          </template>
          <v-radio
            label="Yes"
            value="Y"
          ></v-radio>
          <v-radio
            label="No"
            value="N"
          ></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="4">
        <v-autocomplete
          outlined
          dense
          v-model="record.PrimCurr"
          :items="currencies"
          item-text="currency"
          item-value="CurrCode"
          label="Primary Currency"
        ></v-autocomplete>
      </v-col>

      <v-col cols="4">
        <v-autocomplete
          outlined
          dense
          v-model="record.AddCurr1"
          :items="currencies"
          item-text="currency"
          item-value="CurrCode"
          label="Additional Currency 1"
        ></v-autocomplete>
      </v-col>

      <v-col cols="4">
        <v-autocomplete
          outlined
          dense
          v-model="record.AddCurr2"
          :items="currencies"
          item-text="currency"
          item-value="CurrCode"
          label="Additional Currency 2"
        ></v-autocomplete>
      </v-col>

      <v-col cols="4">
        <v-autocomplete
          outlined
          dense
          v-model="record.BASE_NUM"
          :items="priceList"
          item-text="ListName"
          item-value="id"
          label="Base Price List"
        ></v-autocomplete>
      </v-col>

      <v-col cols="4">
        <v-text-field
          v-model="record.Factor"
          label="Default Factor"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-btn
          color="accent"
          @click="sendData"
          :loading="loader"
        >
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>

    </v-row>
  </v-form>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
    currencies: {
      type: Array,
    },
  },
  data: () => ({
    record: {},
    priceList: [],
    loader: false,
    date: new Date().toISOString().substr(0, 10),
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    setDate(date) {
      this.record.date = date;
    },
    sendData() {
      this.record.country_id = 1;
      this.loader = true;
      this.$emit("data", this.record);
    },
    getPriceList() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/price_lists`)
        .then((res) => {
          self.priceList = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getPriceList();
  },
};
</script>